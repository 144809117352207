"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortenEthAddress = void 0;
/**
 * Shorten ethereum address to a string formatted as:
 *  0x, first 4 digits, ellipsis, last 4 digits
 *
 * @exports
 * @param {string} address
 * @returns {string}
 */
const shortenEthAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};
exports.shortenEthAddress = shortenEthAddress;
