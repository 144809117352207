"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["USER"] = "USER";
    UserRole["QUANTIFIER"] = "QUANTIFIER";
    UserRole["FORWARDER"] = "FORWARDER";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
